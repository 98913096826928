export default {
  errors: {
    auth: {
      permission_denied: 'Permission refusée',
      permission_denied_details:
        "Vous n'êtes pas autorisé à accéder à cette page",
      plan_subscription_upgrade: 'Impossible to passer à un nouveau plan',
    },
    limit_reach: "Vous avez atteint le maximum d'entités EnContinu inclus dans votre plan. Contactez nous pour  ajouter des entités ou désactiver une entité existante qui est EnContinu.",  
    billing_context: {
      invoices: 'Impossible de récupérer les factures',
      billingInfo: 'Impossible de récupérer les informations de facturation',
      planSubscription:
        'Impossible de récupérer les détails des plans tarifaires ',
    },
    twitter: {
      username:
        'Doit contenir entre 4 et 15 caractères alphanumériques et traits de soulignement',
    },
    must_be_16_years: 'L’individu doit être âgé de 16 ans ou plus.',
    credentials: 'Veuillez vérifier vos accès',
    error: 'Erreur',
    invalid: 'invalide',
    password_length: 'le mot de passe doit être de plus de 8 charactère',
    no_lowercase: 'doit inclure au moins une lettre minuscule',
    no_uppercase: 'doit inclure au moins une lettre majuscule',
    no_number: 'doit inclure au moins un numéro',
    no_active_account:
      'aucun compte d’utilisateur délégué associé à cet utilisateur',
    not_found: 'non trouvé',
    old_password_mismatch:
      "l'ancien mot de passe ne correspond pas au mot de passe actuel",
    passwords_mismatch: 'les mots de passe ne  \n correspondent pas\n',
    required: 'obligatoire',
    too_many: 'Une valeur seulement',
    no_special_chars: 'doit inclure au moins un caractère spécial',
    reset_token_expired: 'réinitialiser jeton expiré',
    server_error: 'erreur du serveur',
    server_unreachable: 'serveur inaccessible',
    too_long: 'trop long',
    too_short: 'doit avoir au moins {{count}} caractères',
    unauthorized: 'non autorisé',
    talent_already_exist: 'Ce Talent fait déjà partie de votre compagnie',
    admin_delegate_already_exist:
      "L'utilisateur <{{email}}> est déjà administrateur ou délégué dans votre entreprise",
    admin_delegate_account_exist: 'Le nom de la compagnie existe déjà',
    company_name_exists: 'Cette organisation existe déjà',
    company_plan_expired: 'le plan de la compagnie est expiré',
    form_submit: "Une erreur s'est produite lors de la soumission",
    try_again: 'Réessayer',
    company_alreay_exists: 'La compagnie existe déjà',
    too_many_companies: 'Plus de 3 organisations',
    no_twitter_account_found: `Le nom d'utilisateur Twitter n'existe pas`,
    invoices: {
      no_invoices: 'Pas de factures encore',
      fetch_invoices: 'Impossible de récupérer les factures',
    },
    validation_limit_reached: {
      title: 'Limite de validations atteintes.',
      description:
        '{{ company }} a atteint le maximum de validations permises dans le plan. Réhaussez votre plan ou achetez un bundle pour effectuer une nouvelle validation.  ',
    },
    live_update_not_available: {
      title: 'Non disponible dans votre plan',
      description:
        'La mise à jour en continue est uniquement disponible dans le plan Business.',
      description_SECURITY:
        "La mise à jour en continue n'est pas disponible dans votre plan. Pour mettre à jour, veuillez envoyer un courriel à contact@valital.com",
    },
    validations: {
      fetch_individuals: 'Impossible de récupérer les individus',
      archive_individual: "Impossible d'archiver l'individu sélectionnée",
      unarchive_individual: "Impossible d'activer l'individu sélectionnée",
      delete_individual: "Impossible de supprimer l'individu sélectionnée",
      new_validation: "Impossible d'exécuter la validation",
      live_update:
        'Impossible de modifier les alertes EnContinu pour cet individu',
      fetch_locations: 'Impossible de récupérer les localisations',
      fetch_organisations: 'Impossible de récupérer les organisations',
      fetch_validatedBy: 'Impossible de récupérer les validateurs',
      
    },
    lev_validations: {
      live_update:
        'Impossible de modifier les alertes EnContinu pour cette organisation',
      archive_individual: "Impossible d'archiver l'organisation sélectionnée",
      unarchive_individual: "Impossible d'activer l'organisation sélectionnée",
      delete_individual: "Impossible de supprimer l'organisation sélectionnée",
    },
    no_organization: 'Titre sans entreprise',
  },
  warnings: {
    domain_mismatch: 'Attention: le domaine ne correspond pas au vôtre',
    personal_email: 'Un courriel personnel est entré',
    individual_already_entered: `Détails individuels déjà saisis. Veuillez modifier les détails de l'utilisateur ou ajouter d'autres détails d'utilisateur pour continuer.`,
    company_field_blank:
      'L’ajout d’une nom de l’entreprise étend les capacités de recherche. Voulez-vous poursuivre sans la saisie de ces informations?',
      country_field_blank: 'L’ajout de la “Ville” augmenterait la probabilité d’obtenir un identifiant plus fort.',
    invalid_twitter_screenname:
      "Ce nom d'utilisateur Twitter n'existe pas. Veuillez modifier le nom d'utilisateur saisi ou laisser le champ vide pour continuer.",
    cancel_individuals:
      'Tous les individus seront supprimés. Cliquez sur ANNULER pour continuer.',
    cancel_organizations:
      'Toutes les organisations seront supprimées. Cliquez sur ANNULER pour continuer.',
    individual_exists:
      "L'individu existe déjà. Soumettez les données à revalider.",
    last_validated: 'Précédemment validé',
    last_updated:
      'Cette personne a été validée pour la dernière fois le {{date}} et sera mise à jour. Editez le formulaire pour modifier ses informations ou pour valider une nouvelle personne.',
    lev_last_updated:
      'Cette organisation a été validée pour la dernière fois le {{date}} et sera mise à jour. Editez le formulaire pour modifier ses informations ou pour valider une nouvelle organisation.',
    validations: {
      archive: {
        title: "Archiver l'individu",
        description: 'Êtes-vous sûr de vouloir archiver {{name}}?',
      },
      unarchive: {
        title: "Activer l'individu",
        description: 'Êtes-vous sûr de vouloir activer {{name}}?',
      },
      delete: {
        title: "Supprimer l'individu",
        description: 'Êtes-vous sûr de vouloir supprimer {{name}}?',
      },
    },
    lev_validations: {
      archive: {
        title: "Archiver l'organisation",
        description: 'Êtes-vous sûr de vouloir archiver {{name}}?',
      },
      unarchive: {
        title: "Activer l'organisation",
        description: 'Êtes-vous sûr de vouloir activer {{name}}?',
      },
      delete: {
        title: "Supprimer l'organisation",
        description: 'Êtes-vous sûr de vouloir supprimer {{name}}?',
      },
    },
  },
  success: {
    request_reference: 'Référence demandée',
    talent_invited: 'Talent invité avec succès à votre compagnie',
    billing: {
      form_success_update:
        'Les informations de facturation ont bien été mises à jour',
      form_success_save: 'Détails de paiement enregistrés avec succès!',
    },
    validations: {
      archive_individual: 'Individu archivé avec succès',
      unarchive_individual: 'Individu activé avec succès',
      delete_individual: 'Individu supprimer avec succès',
      update_privacy:'Statut de confidentialité mis à jour avec succès',
      new_validation: 'Nouvelle validation commencée',
      live_update:
        'Les alerts EnContinu pour cet individu sont maintenant {{ onOrOff }}',
      update_privacy_private: 'Cette validation est maintenant privée.',
      update_privacy_public: 'Cette validation est maintenant partagée avec votre équipe.'
    },
    lev_validations: {
      live_update:
        'Les alerts EnContinu pour cet organisation sont maintenant {{ onOrOff }}',
      archive_individual: 'Organisation archivé avec succès',
      unarchive_individual: 'Organisation activé avec succès',
      delete_individual: 'Organization supprimer avec succès',
    },
  },
  helpers: {
    year: 'AAAA',
  },
  tooltip: {
    validation_limit_reached: 'Limite de validations atteinte',
    user_limit_reached:
      "Vous avez atteint votre limite d'utilisateurs dans votre plan",
    purchase_bundle: 'Achetez un bundle',
  },
  report: {
    loading: 'Chargement...',
    download: 'Télécharger',
    customize: "Rapport personnalisé",
    full: "Rapport Complet",
    current: 'Rapport Actuel',
    filters: "filtres appliqués",
    select: 'Sélectionner les liens',
    links: 'lien',
    summary: {
      request_summaries: 'Request Summaries',
      available_credits: '{{count}} summaries available',
      request_sent: 'Your request has been submitted. A member of our team will reach out shortly.',
      request_failed: 'Failed to send summary request'
    },
  },
};
