import React, { lazy, Suspense, Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import NavBar from 'containers/navbar';
import 'App.scss';

const InfoBanner = lazy(() => import('components/InfoBanner'));
const Drawer = lazy(() => import('containers/drawer'));
const BottomBanner = lazy(() => import('components/bottom-banner'));
const MobileMenuDrawer = lazy(() => import('containers/mobile-menu-drawer'));

/* eslint-disable */

const useStyles = makeStyles((theme) => ({
  error: {
    display: 'flex',
    alignItems: 'center',  // Center content vertically
    justifyContent: 'center',  // Center content horizontally
    textAlign: 'center',  // Center text within the Alert
    background: 'rgb(255, 207, 207)' 
  },

  warning: {
    display: 'flex',
    alignItems: 'center',  // Center content vertically
    justifyContent: 'center',  // Center content horizontally
    textAlign: 'center',  // Center text within the Alert
    background: 'rgb(252, 244, 199)'
  },

  info: {
    display: 'flex',
    alignItems: 'center',  // Center content vertically
    justifyContent: 'center',  // Center content horizontally
    textAlign: 'center',  // Center text within the Alert
    background:'rgb(204, 232, 252)',
  },

  success: {
    display: 'flex',
    alignItems: 'center',  // Center content vertically
    justifyContent: 'center',  // Center content horizontally
    textAlign: 'center',  // Center text within the Alert
    background: 'rgb(217, 252, 222)',
  },
}));

const withBaseElements: BaseElements = WrappedComponent => {
  const BaseElementsComponent = ({ banner }) => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(null);
    const [notification, setNotification] = useState(
      null
      // {
      //   type:'warning',
      //   textFr:`Nous avons identifié un problème et le traitement des validations prend plus de temps que d'habitude. Merci de votre patience pendant que nous travaillons à une résolution. `,
      //   textEn:'We have identified an issue and validations are taking longer than usual to process. Thank you for your patience as we are working towards a resolution.'
      // }
    )
    const classes = useStyles();
    const history = useHistory()
    const { t, i18n } = useTranslation();


    useEffect(() => {
      const getNotification = async () => {
        let notification = null
        try {
          let resp = await axios.get(
            `${process.env.REACT_APP_NODE_SERVER_URL}/app/platform-notifications`
          )

          if (resp.status === 200) {
            notification = resp.data.notification
          }

        } catch (err) {
          notification = null
        }

        setNotification(notification)
      }
      // getNotification()
    }, [history.location.pathname])

    return (
      <>
        <Suspense fallback={<Fragment />}>
          {notification && <Alert severity={notification?.type ?? 'info'} className={classes[notification.type ?? 'info']} >
            <Typography variant='subtitle1'>
              { i18n.language?.includes('fr') ? notification.textFr : notification.textEn}
            </Typography>
          </Alert>}

          <NavBar setMobileMenuOpen={setMobileMenuOpen} />

          <MobileMenuDrawer
            isOpen={isMobileMenuOpen}
            setMobileMenuOpen={setMobileMenuOpen}
          />
        </Suspense>

        <div className="app-content">
          <Suspense fallback={<Fragment />}>
            <Drawer />
          </Suspense>

          <WrappedComponent />

          <Suspense fallback={<Fragment />}>
            <BottomBanner />
          </Suspense>
        </div>
      </>
    );
  };

  const wrappedComponentName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';
  BaseElementsComponent.displayName = `Authenticated(${wrappedComponentName})`;

  return BaseElementsComponent;
};

type BaseElements = (
  WrappedComponent: ComponentType<any>,
) => ComponentType<any>;

export default withBaseElements;
