// @flow
import * as React from 'react';

/**
 * Plus svg
 *
 * @param props
 * @return {XML}
 */
export default (props: {}) => {

    return (
      <svg width="16" height="4" viewBox="0 0 16 4" fill="none">
        <path d="M0.659188 3.376V0.0159998H15.2992V3.376H0.659188Z" fill="black"/>
      </svg>
    );

}
